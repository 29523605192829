@import '../app/variable.scss';
@import '../app/placeholder.scss';

.main-history {
  background: $gray3-color;
  border-bottom: .1rem solid #C0C0C0;

  @media screen and (min-width: 666px) {
    .last-add-wrapper {
      flex-wrap: wrap;
    }
  }
  @media screen and (max-width: 666px) {
    .last-add-wrapper {
      padding-bottom: 4rem;
    }
      .last-add-wrapper-swiper{
        .swiper-pagination {
          & > .swiper-pagination-bullet {
            //width: 12% ;
          }
        }
      }
    }

  .slider-history {
    width: 100%;
    max-width: 100%;
    //max-height: 100vh;
    min-height: 0;
    min-width: 0;
  }

  .swiper-pagination {
    padding-right: 1.8rem;
    & > .swiper-pagination-bullet {
      width: 22%;
      height: .2rem;
      background: #9da0a3;
      border-radius: unset !important;
      transition: all .5s linear;
    }

    & > .swiper-pagination-bullet-active {
      background: $black;
    }
  }

  .swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet, .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 !important;
  }

  .swiper-slide {
    width: auto;
    flex-shrink: 0;
    height: unset !important;
    display: block;
    background: $white;
    border: .1rem solid $stroke-color;
  }

  .slider-history__slide {
    @media screen and (min-width: 666px) {
      width: calc((100% - 4.5rem) / 4) !important;
    }
  }

  .swiper-wrapper {
    max-height: 100%;
    height: 100%;
    display: flex;
    @media screen and (min-width: 666px) {
      gap: 1.5rem;
    }
    @media screen and (max-width: 666px) {
      padding-bottom: 4rem;
    }
  }

  &__wrapper {
    padding: 6.1rem 4.7rem 8.4rem;
    display: flex;
    flex-direction: column;
    gap: 5rem;
    @media screen and (max-width: 666px) {
      padding: 3.7rem 0 7rem 1.8rem;
      overflow: hidden;
      gap: 3.6rem;
    }
    & .history-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media screen and (max-width: 666px) {
        & > a{
          display: none;
        }
      }
      & > h2 {
        color: $green-color;
        font-family: $family_Ermilov;
        font-style: normal;
        font-weight: 700;
        font-size: 5rem;
        line-height: 6rem;
        text-transform: uppercase;
        @media screen and (max-width: 666px) {
          font-size: 3.8rem;
          line-height: 4.4rem;
        }
      }

      & > a > p {
        color: #465558;
      }

      & > a > p:hover {
        color: #212121;
      }
    }

    & .history-button {
      display: flex;
      justify-content: center;
      align-items: center;
      @media screen and (max-width: 666px) {
        padding-right: 1.8rem;
      }
      @media screen and (min-width: 666px) {
        display: none;
      }
      & > a {
        width: 33rem;
      }
      & p {
        color: $darkGray-color;
      }
    }
  }
}
.main-last-add .history-button{
  @media screen and (min-width: 666px) {
    display: flex;
  }
}
.list-cards {
  display: flex;
  gap: 1.5rem;
  justify-content: center;
  flex-wrap: wrap;

  &__card {
    .card-img {
      height: 33rem;
      @media screen and (max-width: 1440px) {
        height: 23vw;
      }
      @media screen and (max-width: 666px) {
        height: 85vw;
      }
    }
    &:hover{
      .card-img, .card-info, .icon{
        border: 1px solid #212121;
      }
      .card-img{
        border-bottom:none;
      }
    }

    .card-img > img {
      //height: 32.5rem;
    }

    .card-info {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 3rem 1.5rem 3.6rem;
      gap: 2.7rem;
      border: 1px solid transparent;

      & > p {
        color: $green-color;
      }
    }

    .icon {
      position: absolute;
      background: $light-green-color;
      border: 1px solid $stroke-color;
      border-radius: 50%;
      top: -3rem;
      left: 50%;
      width: 5.6rem;
      height: 5.6rem;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: translateX(-50%);

      & > img {
        width: 3.2rem;
        height: 3.2rem;
      }
    }
  }
}
.history-title-last > h2{
  font-family: $family_Nami!important;
  font-style: normal!important;
  font-weight: 600!important;
  font-size: 3.5rem!important;
  line-height: 4rem!important;
  color: #465558!important;
  text-transform: inherit!important;
}