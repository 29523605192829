@import 'variable.scss';
//шаблон класів для елементів з однаковими чи сходжими стилями

/* ============================ styles from placeholder START ================================================================= */
%text {
   font-family: $font-family;
   font-weight: 600;
   font-size: 2.6rem;
   line-height: 1.19;
   color: green;
   //для тексту .header__text > p
   & > * {
      color: red;
      border: solid 1px tomato;
   }
}

//@extend %text;

%title-Ex {
   & > * {
      font-family: $family_Inter;
      font-weight: 700;
      font-size: 3.6rem;
      line-height: 3.6rem;
   }
}


/* ============================ styles from placeholder END ================================================================= */
