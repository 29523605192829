@import '../app/variable.scss';
@import '../app/placeholder.scss';

.main-tell-history{
  & .container{
    position: relative;
    display: flex;
    justify-content: center;
  }
  &__wrapper{
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    padding: 7.5rem 0 7rem;
    max-width: 43rem;
    @media screen and (max-width: 666px) {
      padding: 3.5rem 2.6rem;
    }
    & > h2{
      color: $green-color;
      padding-bottom: 1rem;
      font-family: $family_Ermilov;
      font-style: normal;
      font-weight: 700;
      font-size: 5rem;
      line-height: 6rem;
      text-transform: uppercase;
      .bg-change-animate{
        background: #465558;
        color: white;
      }

      & > .word{
        width: max-content;
        animation: caret 1s steps(50) infinite;
        border-right: .1rem solid #465558;
      }
      @media screen and (max-width: 666px) {
        font-size: 3.8rem;
        line-height: 4.4rem;
      }
    }
    & > h3{
      color: $darkGray-color;
      padding-bottom: 4rem;
      @media screen and (max-width: 666px) {
        font-size: 1.8rem;
        line-height: 2.6rem;
      }
    }
    & .button-primary{
      width: 20rem;
      height: 5rem;
      @media screen and (max-width: 666px) {
        width: 100%;
      }
    }
  }
  &-left{
    position: absolute;
    left: 0;
    top: 0;
    width: 44.2rem;
    height: 38.6rem;
    @media screen and (max-width: 900px) {
      width: 34.2rem;
      height: 28.6rem;
    }
    @media screen and (max-width: 666px) {
      display: none;
    }
  }

  &-right{
    position: absolute;
    right: 0;
    top: 10%;
    width: 41.1rem;
    height: 45.1rem;
    @media screen and (max-width: 900px) {
      width: 31.1rem;
      height: 35.1rem;
    }
    @media screen and (max-width: 666px) {
      display: none;
    }
  }
}

@keyframes typing {
  from { width: 0 }
}

@keyframes caret {
  50% { border-right-color: transparent; }
}