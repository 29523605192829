@import '../app/variable.scss';
@import '../app/placeholder.scss';

.history, .rebuild{
  .sidebar form{
    @media screen and (max-width: 666px) {
      display: none;
    }
  }
  .sidebar{
    .select2.select2-container{
      width: 100% !important;
    }
  }
  @media screen and (min-width: 666px){
    .form-objects__inputs-wrapper:nth-child(2) > label {
      margin: 0;
    }
  }
  .article-header{
    display: flex;
    gap: 1.5rem;
    @media screen and (max-width: 666px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  &-header{
    display: flex;
    justify-content: space-between;
    padding: 7rem 5rem;
    background: $bg-color;
    align-items: center;
    @media screen and (max-width: 666px) {
      background: #EDEDED;
      padding: 3.4rem 1.7rem 1.9rem;
    }
    & > h2{
      font-family: $family_Ermilov;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 5rem;
      line-height: 6rem;
      color: #609F90;
      @media screen and (max-width: 666px) {
        font-size: 3.8rem;
        line-height: 4.4rem;
      }
    }
  }
  &__wrapper{
    display: flex;
    gap: 2.9rem;
    padding: 4rem 5rem 8rem;
    @media screen and (max-width: 666px) {
      flex-direction: column-reverse;
      padding: 0;
      gap: 0;
    }
  }
  &-pagination{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 6.3rem;
    @media screen and (max-width: 666px) {
      padding-top: 3rem;
    }
  }
  .form-objects__inputs{
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 666px) {

    }
    & > h3{
      padding-bottom: 3.8rem;
    }

    &-wrapper{
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      flex-direction: column;
      border-right: none;
      gap: .5rem;
      @media screen and (max-width: 666px) {

      }
      &:last-child{
        margin: 0 2rem;
      }
      &:nth-child(2){
        gap: 0;
        @media screen and (max-width: 666px) {
          margin-bottom: 0;
        }
      }
    }
    &-button{
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .button-base-dameges{
    width: 100%;
  }
  .base-datapicker{
    margin: 0;
    padding: 1.2rem 0 3.8rem;
  }
  .base-datapicker_one{
    position: relative;
      width: 50%;
  }
  #datepicker1, #datepicker2{
    width: 100% !important;
  }
  #ui-datepicker-div {
    left: 4vw !important;
  }
  .vw-26, .vw-25, .vw-30{
    width: 100%;
    max-width: unset;
    margin-left: 0;
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
  }
  .vw-9 {
      width: 50%;
      max-width: 16.5rem;
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
  }

}
.history-Picker{
  left: 4vw !important;
  @media screen and (max-width: 666px) {
    left: calc(50% - 14.5rem ) !important;
  }
}
.modal{
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  border: .1rem solid #465558;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;

  &-bg{
    position: fixed;
    top: 0;
    left: 0;
    background: #2121216e;
    width: 100%;
    height: 100%;
    z-index: 0;
  }
  &__wrapper{
    max-height: 66rem;
    background: #FFFFFF;
    width: 90vw;
    border: .1rem solid #465558;
    border-radius: 1rem;
    padding: 2.2rem 2rem 4.3rem;
    overflow-y: scroll;
    z-index: 1;
  }
}
.show{
  display: flex!important;
}
.filter-big{
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  max-width: 21rem;
  width: 100%;
  background: #FFFFFF;
  border: .1rem solid #DEE3E7;
  border-radius: 1rem;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  gap: .7rem;
  color: #465558;
  & svg{
    width: 3rem;
    height: 1.5rem;
  }
  @media screen and (min-width: 666px) {
    display: none;
  }
  &:active{
    border: 1px solid #465558;
    background: #DEE3E7;
  }
}
.no-scroll{
  overflow: hidden;
  height: 100vh;
}