@import "variable.scss";

html {
	font-size: 10px;
	scroll-behavior: smooth;
	@media only screen and (max-width: 1440px) {
		font-size: 10px;
	}

	@media only screen and (max-width: 1366px) {
		font-size: 9.49px;
	}

	@media only screen and ( max-width: 1280px){
		font-size: 8.88px;
	}

	@media only screen and (max-width: 1024px) {
		font-size: 7.11px;
	}

	@media only screen and (max-width: 768px) {
		font-size: 5.33px;
	}

	@media only screen and (max-width: 666px) {
		font-size: 10px;
	}
}
* {
	padding: 0;
	margin: 0;
	border: 0;
}
*,
*:before,
*:after {
	box-sizing: border-box;
}
:focus,
:active {
	// outline: none;
}
a:focus,
a:active {
	// outline: none;
}
html,
body {
	height: 100%;
}
body {
	margin: 0;
	padding: 0;
	color: $black;
	//font-family: $font-family, sans-serif;
	//text-rendering: optimizeLegibility;
	-ms-text-size-adjust: 100%;
	-moz-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
input[type="radio"]:checked,
input[type="radio"]:focus {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	outline: none;
	background-color: transparent;
}

input[type="radio"]::-moz-focus-inner {
	border: 0;
}

input,
button,
textarea,
optgroup,
select {
	//font-family: $font-family;
	font-size: inherit;
}
button {
	cursor: pointer;
	color: inherit;
	background-color: inherit;
	//прибрати синє миготіння в хромі при кліку
	-webkit-tap-highlight-color: transparent;
	&:disabled {
		cursor: auto;
	}
}
picture {
	display: block;
}
p {
	margin: 0;
	padding: 0;
}
a {
	cursor: pointer;
	display: inline-block;
	color: inherit;
	//прибрати синє миготіння в хромі при кліку
	-webkit-tap-highlight-color: transparent;
}
a:link,
a:visited {
	text-decoration: none;
}
a:hover {
	text-decoration: none;
}
ol,
ul,
li {
	list-style: none;
	margin: 0;
	padding: 0;
}
img {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
	//vertical-align: top;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: inherit;
	font-size: inherit;
	padding: 0;
	margin: 0;
}
blockquote {
	margin: 0;
	padding: 0;
}
//прибрати стрілки для input type="number"
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
/* Firefox */
input[type=number] {
	-moz-appearance: textfield;
}
