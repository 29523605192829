@import '../app/variable.scss';
@import '../app/placeholder.scss';

.contacts{
  &-wrapper{
    display: flex;
    flex-direction: column;
    padding: 5.8rem 5rem 9.3rem;
    gap: 7.7rem;
    @media screen and (max-width: 666px) {
      padding: 2.8rem 2rem 6.3rem;
      gap: 2.7rem;
    }
    &_img{
      width: 134.1rem;
      height: 64.3rem;
    }
    &__info{
      display: flex;
      flex-direction: column;
      gap: 4.6rem;
    }
    .address, .contact{
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
    .contact > a{
      font-family: $family_Inter;
      font-style: normal;
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 2.4rem;
      letter-spacing: 0.02em;
      color: #465558;
    }
  }
}