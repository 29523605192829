@import '../app/variable.scss';
@import '../app/placeholder.scss';

.swiper-slide__content {
  height: 30rem;
}

.main-slider {
  background: #EDEDED;


  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    height: 100%;
    padding: 4rem 0 20rem;
    position: relative;
    @media screen and (max-width: 666px) {
      padding: 3.9rem 2rem 2.4rem;
    }
  }
  .main-slider-mobile{
    width: 100%;
    @media screen and (min-width: 666px) {
      display: none;
    }
      .list-cards__card{
        &:hover{
          .card-info{
            border: none;
          }
        }
    }
    .card-img{
      max-height: 21rem;
      border: .1rem solid #465558;
      &:hover{

      }
    }
    .icon{
      border: .1rem solid #465558;
    }
    .card-info{
      padding: 3.2rem 0 0 0;
      gap: 1rem;
      &:hover{

      }
    }
  }

  .swiper-container {
    width: 90%;
    height: 54rem;
    @media screen and (max-width: 666px) {
     display: none;
    }
  }

  .swiper-container > .swiper-slide__content {
    position: absolute;
    top: 0;
  }
  .main-slider-mobile .swiper-slide {
    flex-direction: column;
  }
  .swiper {
    width: 100%;
    height: 30rem;
    margin-left: auto;
    margin-right: auto;
  }

  .swiper-slide {
    background-size: cover;
    background-position: center;
  }
  #slider-history2{
    .list-cards__card{
      width: 100%;
      height: inherit;
      .card-img{
        height: 20rem;
        border: .1rem solid #465558;
      }
    }
  }
  .slider-history2{
    @media screen and (min-width: 666px) {
      display: none;
    }
    .swiper-pagination {
      padding-right: 1.8rem;
      & > .swiper-pagination-bullet {
        width: 22%;
        height: .2rem;
        background: #9da0a3;
        border-radius: unset !important;
        transition: all .5s linear;
      }

      & > .swiper-pagination-bullet-active {
        background: $black;
      }
    }

    .swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet, .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
      margin: 0 !important;
    }
  }
  .swiper-container .swiper-slide {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .1s linear;
    &__img{
      background: #EDEDED;
    }
    & .slide-info{
      display: none;
    }
  }
  .swiper-container .swiper-slide {
    transform: scale(2.2);
    position: relative;

    &.swiper-slide-active {
      transform: scale(3.4);
      z-index: 3;
      & .slide-info{
        display: flex !important;
        opacity: 1 !important;
      }
      & > .swiper-slide__img > img {
        opacity: 1 !important;
      }
    }

    &.swiper-slide-next {
      transform: scale(2.7);
      z-index: 2;
    }

    &.swiper-slide-prev {
      transform: scale(2.7);
      z-index: 1;
    }
  }

  .swiper-container .swiper-slide__img > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.5;
  }

  .swiper-pagination-main {
    padding-bottom: 4rem;

    & > .swiper-pagination-bullet {
      width: 13.5rem;
      height: .2rem;
      background: #9da0a3;
      border-radius: unset !important;
      transition: all .5s linear;
    }

    & > .swiper-pagination-bullet-active {
      background: $black;
    }
  }

  .swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet, .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 !important;
  }

  .swiper-button-prev-main, .swiper-button-next-main {
    position: absolute;
    top: 40%;
    width: calc(var(--swiper-navigation-size) / 44 * 27);
    height: var(--swiper-navigation-size);
    margin-top: calc(0 - (var(--swiper-navigation-size) / 2));
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .swiper-button-prev-main, .swiper-button-next-main > svg{
    width: 3rem;
    height: 4.2rem;
  }
  .swiper-button-prev-main {
    left: calc(50% - 47rem);;
    right: auto;
  }
  .swiper-button-next-main {
    right: calc(50% - 47rem);;
    left: auto;
  }
    .swiper-button-next-main{
    transform: rotate(-180deg);
  }
  .swiper-counter{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 5rem;
    right: 10%;
    z-index: 10;
    gap: .7rem;
    & > h2{
      color: $darkGray-color;
      font-size: 2.2rem;
      line-height: 3rem;
    }
    & > h4{
      color: $gray2-color;
    }
  }
  .slide-info{
    opacity: 0.5;
    transform: scale(0.3)!important;
    display: flex;
    width: 66rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3.3rem 0 4.5rem;
    gap: 2.7rem;
    background: $white;
    position: absolute;
    border: .1rem solid $darkGray-color;
    text-align: center;
    top: 63%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.3) !important;
    &__icon{
      position: absolute;
      background: $light-green-color;
      border: .1rem solid #465558;
      border-radius: 50%;
      top: -3rem;
      left: 50%;
      width: 5.6rem;
      height: 5.6rem;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: translateX(-50%);
      & > svg{
        width: 3.2rem;
        height: 3.2rem;
      }
    }
    & > p{
      color: $green-color;
    }
  }
}
