@import "variable.scss";

body {
   // Скрол заблокаваний
   &.no-scroll {
      overflow: hidden;
      touch-action: none;
      overscroll-behavior: none;
   }
}
.app {
   display: flex;
   flex-direction: column;
   min-height: 100%;
}
.visually-hidden {
   position: absolute;
   width: 1px;
   height: 1px;
   margin: -1px;
   border: 0;
   padding: 0;
   clip: rect(0 0 0 0);
   overflow: hidden;
}
.main {
   flex: 1 1 auto;
}
.error {
   & .main {
      margin-top: 0;
   }

}
.container {
   max-width: 144rem;
   width: 100%;
   margin: 0 auto;
   @media screen and (max-width: 412px) {
   }
}
.info {
   display: flex;
   align-items: center;
   justify-content: space-between;
   &:not(:last-child) {
      margin-bottom: 3rem;
   }


}

.share {
   display: flex;
   align-items: center;
   &:not(:last-child) {
      margin-bottom: 3rem;
   }
   &__icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 5rem;
      height: 5rem;
      border: 1px solid #BBBBBB;
      border-radius: 1rem;
      cursor: pointer;
      //background-color: $light-color;
      //transition: background-color .25s $time-distribution-function;
      &:hover {
         background-color: #DACEFE;
      }
      & > * {
         width: 2.5rem;
         height: 2.5rem;
      }
   }
   & [data-copy] {
      margin-right: 2rem;
   }
}

.label {
   &:not(:last-child) {
      margin-bottom: 1.5rem;
   }
   &__name {
      & > * {
         font-size: 1.6rem;
         line-height: 2.4rem;
      }
   }
   &__profession {
      & > * {
         font-size: 1.6rem;
         line-height: 2.4rem;
      }
   }

}

.hash{
   &:not(:last-child) {
      margin-bottom: 3rem;
   }
   & > * {
      display: inline-block;
      padding: 0.5rem 1.5rem;
      background: #E1E1E1;
      border-radius: 0.5rem;
      font-weight: 700;
      font-size: 1.6rem;
      line-height: 2.2rem;
   }
}
h1{
   font-family: $family_Ermilov;
   font-style: normal;
   font-weight: 700;
   font-size: 5rem;
   line-height: 6rem;
   text-transform: uppercase;
   color: #609F90;
   @media screen and (max-width: 666px) {
      font-size: 3.8rem;
      line-height: 4.4rem;
   }
}
h2{
   font-family: $family_Nami;
   font-style: normal;
   font-weight: 600;
   font-size: 3.5rem;
   line-height: 4rem;
   color: #465558;
   @media screen and (max-width: 666px) {

   }
}
h3{
   font-family: $family_Nami;
   font-style: normal;
   font-weight: 600;
   font-size: 2.2rem;
   line-height: 3rem;
   letter-spacing: 0.02em;
   color: #465558;
}
h4{
   font-family: $family_Inter;
   font-style: normal;
   font-weight: 400;
   font-size: 1.8rem;
   line-height: 2.6rem;
   letter-spacing: 0.02em;
   color: #465558;
}
h5{
   font-family: $family_Inter;
   font-style: normal;
   font-weight: 400;
   font-size: 1.2rem;
   line-height: 2rem;
   letter-spacing: 0.02em;
   color: #465558;
}
p{
   font-family: $family_Inter;
   font-style: normal;
   font-weight: 400;
   font-size: 1.6rem;
   line-height: 2.4rem;
   letter-spacing: 0.02em;
   color: #465558;
}
.text-button-bold{
   font-family: $family_Inter;
   font-style: normal;
   font-weight: 700;
   font-size: 1.8rem;
   line-height: 2.6rem;
   letter-spacing: 0.02em;
   color: #465558;
   & h2,h3,h4,h5,h6,p{
      font-family: $family_Inter;
      font-style: normal;
      font-weight: 700;
      font-size: 1.8rem;
      line-height: 2.6rem;
      letter-spacing: 0.02em;
      color: #465558;
   }
}
.text-button-slim{
   font-family: $family_Inter;
   font-style: normal;
   font-weight: 400;
   font-size: 1.6rem;
   line-height: 2.2rem;
   letter-spacing: 0.02em;
   & h2,h3,h4,h5,h6,p{
      font-family: $family_Inter;
      font-style: normal;
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 2.2rem;
      letter-spacing: 0.02em;
   }
}
.button-primary{
   display: flex;
   justify-content: center;
   align-items: center;
   height: 5rem;
   background: $light-green-color;
   border: .1rem solid $darkGray-color;
   border-radius: 1rem;
   cursor: pointer;
   color: #465558;
}
.button-primary:hover{
   background: #FFFFFF;
   border: .1rem solid #465558;
   box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.05);
   transition: all .3s;
}
.button-primary:active{
   background: #FFFFFF !important;
   border: 1px solid #212121 !important;
   box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.05);
   transition: all .3s;
   color: #212121;
   & > h2,h3,h4,h5,h6, a{
      color: #212121;
   }
}
.button-secondary{
   display: flex;
   justify-content: center;
   align-items: center;
   height: 5rem;
   background: #F3F3F3;
   border: .2rem solid #DEE3E7;
   border-radius: 1rem;
   cursor: pointer;
}
.button-secondary:hover{
   transition: all .3s;
   background: #DEE3E7;
}
.button-secondary:active{
   border: .1rem solid #465558 !important;
   background: #DEE3E7;
}
.button-three{
   display: flex;
   justify-content: center;
   align-items: center;
   height: 5rem;
   background: #465558;
   border: .1rem solid #DEE3E7;
   border-radius: 1rem;
   cursor: pointer;
   color: #FFFFFF;
}
.button-three:hover{
   transition: all .3s;
   background: #FFFFFF;
   border: .1rem solid #465558;
   box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.05);
   & > h2,h3,h4,h5,h6, a{
      color: #465558;
   }
}
.button-three:active{
   border: .1rem solid #212121 !important;
   background: #FFFFFF!important;
   color: #212121!important;
   & > h2,h3,h4,h5,h6, a{
      color: #212121!important;
   }
}