@import '../app/variable.scss';
@import '../app/placeholder.scss';

.main-base-build-damages{
  &__wrapper{
    display: flex;
    flex-direction: column;
    gap: 4rem;
    padding: 4.6rem 0 4.8rem 4.7rem;
    @media screen and (max-width: 666px) {
      padding: 4rem 1.8rem 5rem;
    }
    & .base-build-damages-info{
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 666px) {
        flex-direction: column;
      }
      &__text{
        display: flex;
        flex-direction: column;
        gap: 2rem;
        max-width: 78rem;
        @media screen and (max-width: 666px) {
          padding-bottom: 2.7rem;
          gap: 1.8rem;
        }
        .desc{
          @media screen and (max-width: 666px) {
            display: none;
          }
        }
        .mobile{
          font-size: 3rem;
          line-height: 3.4rem;
          @media screen and (min-width: 666px) {
            display: none;

          }
        }
        & > h2{
          font-family: $family_Ermilov;
          font-style: normal;
          font-weight: 700;
          font-size: 5rem;
          line-height: 6rem;
          text-transform: uppercase;
          color: $green-color;
          @media screen and (max-width: 666px) {
            font-size: 3.8rem;
            line-height: 4.4rem;
          }
        }
        & > p{
          max-width: 36rem;
        }
      }
      &__numbers{
        display: flex;
        flex-direction: column;
        gap: 1rem;
        @media screen and (min-width: 666px) {
          max-width: 37rem;
        }
        width: 100%;
        & .count-objects{
          border-bottom: .1rem solid #DEE3E7;
          padding-bottom: 2rem;
          margin-bottom: 1rem;
          &__title{
            font-family: $family_Ermilov;
            font-style: normal;
            font-weight: 700;
            font-size: 5rem;
            line-height: 4rem;
            text-transform: uppercase;
            color: $darkGray-color;
            @media screen and (max-width: 666px) {
              font-size: 3.8rem;
              line-height: 4.4rem;
            }
          }
        }
      }
    }
    & .base-build-damages-find{
      display: flex;
      flex-direction: column;
      gap: 2rem;
      &__wrapper{
        display: flex;
        @media screen and (max-width: 666px) {
          flex-direction: column;
        }
        gap: 2rem;
        & .main-search-base{
          width: 16rem;
          @media screen and (max-width: 666px) {
            width: 100%;
          }
        }
      }
    }
  }
}
.select2.select2-container {
  width: 26rem !important;
  font-family: $family_Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.4rem;
  letter-spacing: 0.02em;
  position: relative;
  @media screen and (max-width: 666px) {
    width: unset !important;
  }
}

.select2.select2-container::after{
  content: "";
  position: absolute;
  top: 2.8rem;
  right: 1.5rem;
  //transform: translateY(-50%) rotate(180deg);
  transform: translateY(-50%);
  width: 1.2rem;
  height: .6rem;
  background: url("img/sprite/select-arrow.svg") center/1.2rem .6rem  no-repeat ;
}
.select2.select2-container.select2-container--open:after{
  top: 2.2rem;
  transform: rotate(180deg);
}

.select2.select2-container .select2-selection {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  border: .1rem solid #DEE3E7;
  -webkit-border-radius:1rem;
  -moz-border-radius: 1rem;
  border-radius: 1rem !important;
  height: 5rem;
  margin-bottom: 1.5rem;
  outline: none !important;
  padding: 0.7rem 1rem;
  transition: all .15s ease-in-out;
}

.select2.select2-container .select2-selection .select2-selection__rendered {
  color: #333;
  line-height: 3.2rem;

}

.select2.select2-container .select2-selection .select2-selection__arrow {
  //display: none;
}

.select2.select2-container.select2-container--open .select2-selection.select2-selection--single {
  background: #FFFFFF;

}

.select2.select2-container.select2-container--open .select2-selection.select2-selection--single .select2-selection__arrow {
  color: black;
}


.select2.select2-container .select2-selection--multiple {
  height: auto;
  min-height: 5rem;
}

.select2.select2-container .select2-selection--multiple .select2-search--inline .select2-search__field {
  margin-top: 0;
  height: 3.2rem;
}

.select2.select2-container .select2-selection--multiple .select2-selection__rendered {
  display: block;
  padding: 0 .4rem;
  line-height: 2.9rem;
}

.select2.select2-container .select2-selection--multiple .select2-selection__choice {
  background-color: #f8f8f8;
  border: .1rem solid #465558;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  border-radius: 1rem;
  margin: .4rem .4rem 0 0;
  padding: 0 .6rem 0 2.2rem;
  height: 2.4rem;
  line-height: 2.4rem;
  font-size: 1.2rem;
  position: relative;
}

.select2.select2-container .select2-selection--multiple .select2-selection__choice .select2-selection__choice__remove {
  position: absolute;
  top: 0;
  left: 0;
  height: 2.2rem;
  width: 2.2rem;
  margin: 0;
  text-align: center;
  color: #e74c3c;
  font-weight: bold;
  font-size: 1.6rem;
}

.select2-container .select2-dropdown {
  background: transparent;
  margin-top: -.5rem;
  font-family: $family_Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.4rem;
  letter-spacing: 0.02em;
  border-radius: 1rem;
  border: .1rem solid #DEE3E7 !important;
}

.select2-container .select2-dropdown .select2-search {
  padding: 0;
}

.select2-container .select2-dropdown .select2-search input {
  outline: none !important;
  border: none;
  padding: 1rem 1rem !important;
  border-radius: 1rem;
  border-bottom: .1rem solid #EAEAEA;
}

.select2-container .select2-dropdown .select2-results {
  padding: 0;
}

.select2-container .select2-dropdown .select2-results ul {
  background: #fff;
  border-radius: 0 0 1rem 1rem;
}

.select2-container .select2-dropdown .select2-results ul .select2-results__option--highlighted[aria-selected] {
  background-color:#E2F3EF;
  color: #465558;
}
.select2-container--default.select2-container--open.select2-container--below .select2-selection--single, .select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple{
  border-radius: 1rem !important;
}
.select2-results__option--selectable{
  border-bottom: .1rem solid #EAEAEA;
  padding: 1.3rem 1rem;
}
.select2-container--default .select2-results__option--selected{
  background-color:#E2F3EF;
  color: #465558;
}
.select2-container--default .select2-results>.select2-results__options::-webkit-scrollbar {
  background: transparent;
  width: .6rem;/* color of the tracking area */
}
.select2-container--default .select2-results>.select2-results__options::-webkit-scrollbar-thumb {
  background: #B3BDBB;
  width: .4rem;
  border-radius: 1rem;
}
.select2-container--open .select2-selection__arrow {
  transform: rotate(180deg) !important;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 3.5rem;
  top: 50%;
  right: 0;
  width: 3.5rem;
  margin-top: -1.5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease-in-out;
}

/*the arrow itself */
.select2-container--default .select2-selection--single .select2-selection__arrow b {
  background-image: url('static/app/img/icon-home.svg');
  background-color: transparent;
  background-size: contain;
  border: none !important;
  height: 2rem !important;
  width: 2rem !important;
  margin: auto !important;
  top: auto !important;
  left: auto !important;
}