

$green-color: #609F90;
$gray-color: #EAEAEA;
$red-color: #FF4A4A;
$darkGray-color:#465558;
$white:#FFFFFF;
$black:#212121;
$bg-color:#F1F3F4;
$stroke-color:#DEE3E7;
$light-green-color:#E2F3EF;
$gray2-color:#C0C0C0;
$gray3-color:#EDEDED;


$time-distribution-function: cubic-bezier(0.4, 0, 0.2, 1);

$family_Ermilov: 'Ermilov';
$family_Inter: 'Inter';
$family_Nami: 'Nami';
//
$font-family: $family_Inter;
