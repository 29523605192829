@import '../app/variable.scss';
@import '../app/placeholder.scss';

.article-page{
  background-color: $bg-color;
  &__header{
    padding: 6.8rem 6.2rem 0 5.1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $bg-color;
    & h2{
      font-family: $family_Ermilov;
      color: $green-color;
      font-weight: 700;
      font-size: 5rem;
      line-height: 6rem;
      text-transform: uppercase;
    }
    & p{
      white-space: nowrap;
      color: #B9B9B9;
      font-style: normal;
      font-weight: 400;
      font-size: 1.2rem;
      line-height: 2rem;
      & > span{
        padding: 0 .8rem;
        font-size: 1.5rem;
        color: #C0C0C0;
      }
    }
    @media screen and (max-width: 666px) {
      display: none;
    }
  }
  &__header-mobile{
    @media screen and (min-width: 666px) {
      display: none;
    }
    & h2{
      color: $green-color;
      padding: 3.4rem 1.7rem 0;
      background: #EDEDED;
      text-transform: uppercase;
      font-family: $family_Ermilov;
      @media screen and (max-width: 666px) {
        font-size: 3.8rem;
        line-height: 4.4rem;
      }
    }
  }
  .article{
    width: 100%;
    position: relative;
    padding: 0;
    @media screen and (max-width: 666px) {
      padding: 3.4rem 2.2rem 4rem;
    }
    &-info{

      .card-info{
        position: absolute;
        top: 20.5rem;
        left: 50%;
        transform: translateX(-50%);
        z-index: 100;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .icon {
          background: $light-green-color;
          border: 1px solid $stroke-color;
          border-radius: 50%;
          width: 5.6rem;
          height: 5.6rem;
          display: flex;
          justify-content: center;
          align-items: center;

          & > img {
            width: 3.2rem;
            height: 3.2rem;
          }
        }
        & > p{
          white-space: nowrap;
          color: $green-color;
        }
      }

      @media screen and (min-width: 666px) {
        display: none;
      }
      & h1{
        text-align: center;
        padding-bottom: 3rem;
        font-family: $family_Nami;
        font-style: normal;
        font-weight: 600;
        font-size: 3.5rem;
        line-height: 4rem;
        color: #465558;
        text-transform: none;
      }
      & h4{
        color: $green-color;
        padding-bottom: 1rem;
      }
      & p{
        padding-bottom: 2rem;
      }
      .line{
        width: 100%;
        height: .1rem;
        background: #DEE3E7;
        margin: 1rem 0 3rem;
      }
    }
    .swiper-slide{
      @media screen and (max-width: 666px) {
        background: none;
      }
    }
    #slider-history{
      .list-cards__card{
        width: 100%;
        height: inherit;
        &:hover{
          .card-info, .icon{
            border: none;
          }
        }
        .card-img{
          height: 20rem;
          border: .1rem solid #465558;
        }
      }
    }
    &__main{
      background-color: $bg-color;
      padding: 8.3rem 16rem 0;
      max-width: 144rem;
      @media screen and (max-width: 666px) {
        display: none;
    }
    }
    .slider-history1{
      @media screen and (min-width: 666px) {
        display: none;
      }
      .swiper-pagination {
        padding-right: 1.8rem;
        & > .swiper-pagination-bullet {
          width: 22%;
          height: .2rem;
          background: #9da0a3;
          border-radius: unset !important;
          transition: all .5s linear;
        }

        & > .swiper-pagination-bullet-active {
          background: $black;
        }
      }

      .swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet, .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
        margin: 0 !important;
      }
    }
    &__img{
      width: unset;
      max-width: unset;
      height: 84rem;
      .base-damages-slider{
        height: 75%;
      }
      .base-damages-gallery{
        height: 25%;
      }
      .swiper-wrapper{
        height: 100% !important;
      }
    }
    .base-damages-card__wrapper{
      max-width: 78rem;
      padding-bottom: 4.5rem;
      .card-title{
        h1{
          font-family: $family_Nami;
          font-style: normal;
          font-weight: 600;
          font-size: 3.5rem;
          line-height: 4rem;
          color: #465558;
          text-transform: none;
        }
      }
    }
   .base-damages-card{
     display: flex;
     flex-direction: column;
     &__info{
       padding: 2.5rem 0;
       & > h5{
         display: flex;
       }
       & span{
         padding: 0 1.5rem;
         font-size: 2rem;
         color: #B9B9B9;
       }
     }
   }
  }
  &-card{
    background: $bg-color;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    &__wrapper{
      @media screen and (max-width: 666px) {
        width: 100%;
        padding: 3.2rem 1.9rem 0 ;
        overflow: hidden;
        border-radius: unset;
      }
      padding: 7.8rem 11rem 0;
      background: #FFFFFF;
      box-shadow: .4rem .4rem 1rem rgba(0, 0, 0, 0.04);
      border-radius: 1.6rem;
      max-width: 100rem;
      & > h3{
        padding-bottom: 2rem;
        display: flex;
        justify-content: center;
        font-weight: 600!important;
        @media screen and (max-width: 666px) {
          justify-content: flex-start;
        }
      }
      & > h3:not(:first-child){
        padding-top: 4rem;
      }
      & > p > a{
        color: $green-color;
      }
      .quote{
        border-top: .2rem solid #DEE3E7;
        border-bottom: .2rem solid #DEE3E7;
        padding: 3rem 0;
        display: flex;
        gap: 3rem;
        margin: 4rem 0 0;
        @media screen and (max-width: 666px) {
          flex-direction: column;
        }
        & > svg{
          height: 6rem;
          @media screen and (max-width: 666px) {
            width: 22%;
          }
        }
        & > h2{
          color: #212121;
          @media screen and (max-width: 666px) {
            font-size: 2.2rem;
            line-height: 3rem;
          }
        }
      }
      .post-img{
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 1.2rem;
        padding: 4rem 0;
        & > img{
          height: 100%;
          border: .2rem solid #DEE3E7;
          border-radius: 2rem;
          width: auto;
        }
        &__wrapper{
          display: flex;
          justify-content: space-between;
          @media screen and (max-width: 666px) {
            justify-content: flex-start;
            flex-direction: column;
            gap: 1rem;
          }
          & > p{
            color: $green-color;
            font-size: 1.2rem;
            line-height: 2rem;
          }
        }

      }
      .audio-tag{
        padding: 4rem 0;
        & > audio{
          width: 100%;
        }
      }
      .ukraine-remember{
        border: .2rem solid #1E78FF;
        border-radius: 1rem;
        padding: 2.8rem 3.1rem;
        margin: 7.6rem 0 4rem;
        position: relative;
        @media screen and (max-width: 666px) {
          margin: 6.2rem 0 3.4rem;
        }
        & > h2{
          color: #1E78FF;
          padding-bottom: 3rem;
        }
        & > h3{
          & > span{
            text-decoration: underline;
            text-underline-offset: .6rem;
          }
        }
        & svg{
          width: 7.2rem;
          height: 7.2rem;
        }
        .icon-ukr{
          position: absolute;
          top: -3.6rem;
          right: 5rem;
        }
      }
      .video-tag{
        border: .2rem solid #E2F3EF;
        border-radius: 2rem;
        padding: 3.8rem 6.2rem 6.1rem;
        display: flex;
        flex-direction: column;
        text-align: center;
        gap: 3.6rem;
        margin: 0 -7%;
        margin-bottom: 5.2rem;
        @media screen and (max-width: 666px) {
          margin: 0 0 1.8rem 0;
          padding: 2.3rem 1.3rem 3rem;
        }
        & > h2{
          @media screen and (max-width: 666px) {
            font-size: 2.2rem;
            line-height: 3rem;
          }
        }
        & > iframe{
          width: 100%;
          height: 30vw;
          border-radius: 2rem;
          max-width: 78rem;
          max-height: 44rem;
          @media screen and (max-width: 666px) {
            height: 43vw;
          }
        }
      }
      .article-page__footer{
        display: flex;
        justify-content: space-between;
        background: #F5F7FA;
        padding: 2rem 3rem;
        margin: 0 -14%;
        border-radius: 0 0 1.6rem 1.6rem;
        @media screen and (max-width: 666px) {
          margin: 0 -5%;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 1rem;
          border-radius: unset;
        }
        & > p{
          font-size: 1.2rem;
          line-height: 2rem;
          & > span{
            padding: 0 1rem;
            font-size: 1.5rem;
            color: #C0C0C0;
          }
        }
      }
    }
    .article-share{
      display: flex;
      flex-direction: column;
      padding: 3rem 0 9.4rem;
      gap: 1.6rem;
      @media screen and (max-width: 666px) {
        padding: 1.8rem 0 3.5rem;
        background: white;
        width: 100%;
        align-items: center;
      }
      & > h4{
        font-weight: 600;
      }
      .header-social{
        @media screen and (max-width: 666px) {
          display: flex;
        }
      }
    }
  }
}
.swiper-button-prev-main-mobile > svg, .swiper-button-next-main-mobile > svg{
  width: 3rem;
  height: 4.2rem;
}
.swiper-button-next-main-mobile{
  left: 0;
}
.swiper-button-prev-main-mobile{
  right: 0;
  transform: rotate(-180deg);
}
.swiper-button-next-main-mobile, .swiper-button-prev-main-mobile {
  top: 34%;
  position: absolute;
  width: calc(var(--swiper-navigation-size) / 44 * 27);
  height: var(--swiper-navigation-size);
  margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
  z-index: 10;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}