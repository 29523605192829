@import "variable.scss";
@import 'base.scss';
@import 'common.scss';

@mixin font-face($style-name, $file:$style-name, $weight:normal, $style:normal) {
   $filepath: "/static/fonts/" + $file;
   @font-face {
      font-family: quote($style-name);
      src: url($filepath + ".woff") format('woff'),
         url($filepath + ".woff2") format('woff2'),
      url($filepath + ".ttf") format('truetype');
      font-weight: $weight;
      font-style: $style;
      font-display: swap;
   }
}

@include font-face($family_Ermilov, 'Ermilov', 700, normal);
@include font-face($family_Inter, 'Inter-400Regular', 400, normal);
@include font-face($family_Inter, 'Inter-700SemiBold', 700, normal);
@include font-face($family_Nami, 'NAMU', 600, normal);


