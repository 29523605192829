@import '../app/variable.scss';
@import '../app/placeholder.scss';

.main-search{
  background: #EDEDED;
  .modal__wrapper{
    padding: 3.1rem 2rem 3.7rem;
  }
  .button-search{
    width: 100%;
  }
  &__wrapper{
    padding: 4.2rem 15.4rem 7rem 4rem;
    @media screen and (max-width: 1280px) {
      padding: 4.2rem 4rem 7rem 4rem;
    }
    @media screen and (max-width: 666px) {
      padding: 3.2rem 1.5rem 5rem 1.5rem;
    }
    }
  &-title > h2{
    color: #465558;
    padding-bottom: 5rem;
    @media screen and (max-width: 666px) {
      padding-bottom: 3.2rem;
    }

  }
  &-result{
    display: flex;
    gap: 12rem;
    @media screen and (max-width: 666px) {
      flex-direction: column;
      gap: 3.3rem;
    }
  }
  &-filter{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    @media screen and (max-width: 666px) {
      display: none;
    }
    &__one{
      cursor: pointer;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      padding-bottom: 2rem;
      border-bottom: .1rem solid #DEE3E7;
      width: 21rem;
      overflow: hidden;
      &:hover{
        background: #DEE3E7;
        padding-left: 1rem;
      }
    }
    .active{
      background: #DEE3E7;
      padding-left: 1rem;
    }
  }
  &-cards{
    display: flex;
    flex-wrap: wrap;
    gap: 2.1rem;
    @media screen and (max-width: 666px) {
      gap: unset;
    }
  }
  &-pagination{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 7rem;
  }
  .card-horizontal{
    width: 48%;
    @media screen and (max-width: 666px) {
      width: 100%;
      max-height: unset;
      background: none;
      border-left: none;
      border-right: none;
      border-top: none;
      border-bottom: .2rem solid #DEE3E7;
    }
    &__img{
      @media screen and (max-width: 666px) {
        display: none;
      }
    }
    &__info{
      @media screen and (max-width: 666px) {
        width: 100%;
        max-width: unset;
        gap: 2rem;
      }
      & > p{
        @media screen and (max-width: 666px) {
          font-size: 2.2rem;
          line-height: 3rem;
          font-family: $family_Nami;
          font-style: normal;
          font-weight: 600;
        }
      }
      & > h5{
        @media screen and (max-width: 666px) {
          font-size: 1.6rem;
          line-height: 2.2rem;
        }
      }
    }
  }
  &-input{

    @media screen and (max-width: 666px) {
      display: flex;
      flex-direction: column-reverse;
      gap: 5rem;
    }
    .main-search-input__label{
      @media screen and (max-width: 666px) {
        display: none;
      }
    }
    .label-input-search{
      @media screen and (min-width: 666px) {
        display: none;
      }
    }
    & > h3{
      max-width: 40rem;
      & > a{
        text-decoration: underline;
        color: #609F90;
      }
    }
    .button-three{
      background: #465558;
      color: #FFFFFF;
      width: 15.8rem;
      border: .1rem solid #DEE3E7;
      @media screen and (max-width: 666px) {
        width: 100%;
      }
      &:hover{
        background: #FFFFFF;
        color: #465558;
        border: .1rem solid #465558;
      }
    }
    &__new{
      display: flex;
      @media screen and (max-width: 666px) {
        flex-direction: column;
        gap: 3.5rem;
        align-items: unset;
      }
      gap: 2rem;
      align-items: center;
      padding:2rem 0 5rem;
    }
    &__new-wrapper{
      @media screen and (max-width: 666px) {
        width: 100%;
      }
      border-bottom: .1rem solid #465558;
      width: 30rem;
      height: 4rem;
      display: flex;
      gap: 1rem;
      align-items: center;
      position: relative;
      transition: all 0.5s ease-out;
      & > input{
        width: 100%;
        height: 95%;
        padding: 1rem;
        font-family: $family_Inter;
        font-style: normal;
        font-weight: 400;
        font-size: 1.6rem;
        line-height: 2.2rem;
        letter-spacing: 0.02em;
        color: $darkGray-color;
        background: none;
        &:focus{
          outline: none;
          animation-name: widthFull;
          animation-duration: 0s;
          animation-direction: normal;
        }
      }
      & > svg{
        width: 1.6rem;
        height: 1.6rem;
      }
    }
  }
}
.card-horizontal{
  background: #FFFFFF;
  border: .1rem solid #DEE3E7;
  max-height: 19.3rem;
  &__wrapper{
    display: flex;
  }
  &__img{
    width: 50%;
    & > img{
      height: 19rem;
    }
  }
  &__info{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 22rem;
    padding: 1.5rem;
    & > h5:first-child{
      color: #609F90;
    }
  }
}
.radio-container {
  display: block;
  position: relative;
  padding-left: 3rem;
  margin-bottom: 2.2rem;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  & p{
    white-space: nowrap;
  }
}

.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: .3rem;
  left: 0;
  height: 2rem;
  width: 2rem;
  background-color: $white;
  border-radius: 50%;
  border: .1rem solid #DEE3E7;
}

.radio-container:hover input ~ .checkmark {
  background-color: #465558;
}

.radio-container input:checked ~ .checkmark {
  background-color: #465558;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.radio-container input:checked ~ .checkmark:after {
  display: block;
}

.radio-container .checkmark:after {
  top: .5rem;
  left: .5rem;
  width: .8rem;
  height: .8rem;
  border-radius: 50%;
  background: #FFFFFF;
}