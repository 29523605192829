@import '../app/variable.scss';
@import '../app/placeholder.scss';

.recomended-read{
  background: #F1F3F4;
  &__wrapper{
    display: flex;
    flex-direction: column;
    padding: 7.5rem 4.8rem;
    & > h2{
      padding-bottom: 6.6rem;
    }
    @media screen and (max-width: 666px) {
      display: none;
    }
  }
  &__wrapper-mobile{
    padding: 9rem 3rem 6rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 3rem;
    .list-cards__card{
      background: #FFFFFF;
    }
    @media screen and (min-width: 666px) {
      display: none;
    }
  }
  .card-big{
    display: flex;
    height: 100%;
    max-height: 72rem;
    border: .2rem solid #DEE3E7;
    margin-bottom: 1.2rem;
    background: #FFFFFF;
    &__img{
      width: 50%;
      height: 100%;
    }
    &__info{
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      text-align: center;
      padding: 5.5rem 0;
      .card-info{
        display: flex;
        flex-direction: column;
        align-items: center;
        .icon{
          display: flex;
          justify-content: center;
          align-items: center;
          width: 5.6rem;
          height: 5.6rem;
          background: #E2F3EF;
          border: .1rem solid #DEE3E7;
          border-radius: 50%;
          & img{
            width: 3.2rem;
            height: 3.2rem;
          }
        }
        & p{
          color: #609F90;
        }
      }
      & > h2, > h4{
        max-width: 45rem;
      }
      & > a{
        width: 22rem;
      }
    }
  }
  .card-small{
    display: flex;
    align-items: center;
    height: 100%;
    width: 32.5%;
    max-width: 44rem;
    border: .1rem solid #DEE3E7;
    background: #FFFFFF;

    &__img{
      width: 50%;
      height: 100%;
    }
    &__info {
      & > h5{
        color: #609F90;
      }
      gap:2rem;
      width: 50%;
      display: flex;
      flex-direction: column;
      padding: 2rem 2.2rem 1rem;
    }
  }
  .card-small_wrapper{
    display: flex;
    flex-wrap: wrap;
    gap: 1.2rem;
  }
}