@import '../app/variable.scss';
@import '../app/placeholder.scss';

.author{
  background: #EDEDED;
  &__wrapper{
    padding: 4rem 5rem 8rem;
    background: #EDEDED;
    display: flex;
    @media screen and (max-width: 666px) {
      padding: 3rem 2rem 6rem;
      flex-direction: column-reverse;
    }
  }
  &-category{
    display: flex;
    flex-direction: column;
    border-top: .1rem solid #DEE3E7;
    border-left: .1rem solid #DEE3E7;
    border-right: .1rem solid #DEE3E7;
    width: 16vw;
    height: max-content;
    @media screen and (max-width: 666px) {
      display: none;
    }
    & > h3{
      padding: 3rem 2rem 2rem;
    }
    &__one{
      padding: 1.8rem 2rem;
      border-bottom: .1rem solid #DEE3E7;
      display: flex;
      gap: 1rem;
      cursor: pointer;
      & > h5{
        width: 4rem;
        height: 3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        background: #FFFFFF;
        border-radius: .4rem;

      }
      &:hover{
        background: #F1F3F4;
      }
    }
    .active{
      background: #F1F3F4;
    }

  }
  &-result{
    display: flex;
    flex-direction: column;
    border: .1rem solid #DEE3E7;
    width: 47vw;
    background: #FFFFFF;
    padding: 4rem 5rem 3rem;
    @media screen and (max-width: 666px) {
      width: 100%;
      background: none;
      padding: 0;
      border: none;
    }
    & > h2{
      padding-bottom: 1.2rem;
      @media screen and (max-width: 666px) {
       padding: 3.6rem 0 1.6rem;
      }
    }
    & > h3{
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &__list{
      display: flex;
      flex-direction: column;
      @media screen and (max-width: 666px) {
        display: none;
      }
    }
    &__one{
      display: flex;
      flex-direction: column;
      gap: 1rem;
      border-bottom: .1rem solid #DEE3E7;
      padding: 2rem 0;
      & > h5{
        color: #609F90;
      }
    }
    & > h3{
      padding-top: 5rem;
      @media screen and (max-width: 666px) {
        padding-top: 3rem;
      }
    }
  }
  .author-result__list-mobile{
    @media screen and (min-width: 666px) {
      display: none;
    }
    .line{
      margin: 3rem -2rem;
      width: 108%;
      height: .2rem;
    }
  }
  .author-result-mobile__one{
    & > h5{
      color: #609F90;
      padding-bottom: 2rem;
      @media screen and (max-width: 666px) {
        font-size: 1.6rem;
      }
    }
  }
  &-info{
    display: flex;
    flex-direction: column;
    border: .1rem solid #DEE3E7;
    width: 31vw;
    @media screen and (max-width: 666px) {
      width: 100%;
    }
    &__bg{
      background: #DEE3E7;
      height: 14.4rem;
    }
    &__about{
      display: flex;
      flex-direction: column;
      position: relative;
      padding: 9rem 3.5rem;
      background: #FFFFFF;
      height: 100%;
      @media screen and (max-width: 666px) {
        padding: 9rem 2.5rem 3rem;
        height: unset;
      }
      & > h2{
        color: #609F90;
        padding-bottom: 1rem;
        @media screen and (max-width: 666px) {
          text-align: center;
        }
      }
      & > h3{
        color: #609F90;
        @media screen and (max-width: 666px) {
          text-align: center;
        }
      }
      & > h4{
        padding-bottom: 1.6rem;
      }
      & > p{
        padding-bottom: 3rem;
        @media screen and (max-width: 666px) {
          padding-bottom: 1.6rem;
        }
      }

      .header-social{
        gap: 3rem;
        @media screen and (max-width: 666px) {
          display: flex;
        }
      }
    }
    .avatar{
      position: absolute;
      top: -7rem;
      left: 3.4rem;
      @media screen and (max-width: 666px) {
        left: 50%;
        transform: translateX(-50%);
      }
      & img{
        width: 15rem;
        height: 15rem;
        border: .2rem solid #FFFFFF;
        border-radius: 50%;
        background: #DEE3E7;
      }
    };
  }
  .modal{
    position: fixed;
    &__wrapper{
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .line{
    width: 100%;
    height: .1rem;
    background: #DEE3E7;
    margin: 2.5rem 0;
  }
 .button-search{
    width: 100%;
  }
}