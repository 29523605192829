@import '../app/variable.scss';
@import '../app/placeholder.scss';

.contacts, .questions {
  &-header {
    display: flex;
    justify-content: space-between;
    padding: 7rem 5rem;
    background: $bg-color;
    align-items: center;
    @media screen and (max-width: 666px) {
      background: #EDEDED;
      padding: 3.4rem 1.7rem;
    }

    & > h2 {
      font-family: $family_Ermilov;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 5rem;
      line-height: 6rem;
      color: #609F90;
      @media screen and (max-width: 666px) {
        font-size: 3.8rem;
        line-height: 4.4rem;
      }
    }
  }
  .questions-wrapper{
    padding: 6rem 5rem 8rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    @media screen and (max-width: 666px) {
      padding: 2.2rem 2rem 5rem;
      gap: 1rem;
    }
    &__list{
      display: flex;
      flex-direction: column;
      gap: 4rem;
    }
    &__one{
      display: flex;
      flex-direction: column;
      gap: 2rem;
    }
  }
}