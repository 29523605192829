@import '../app/variable.scss';
@import '../app/placeholder.scss';

.base-damages {
  background: #F1F3F4;
  .history-button{
    @media screen and (min-width: 666px) {
      display: none;
    }
  }

  .select2.select2-container{
    width: 100% !important;
  }
  &-header {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 5rem;
    background: $bg-color;
    @media screen and (max-width: 666px) {
      background: #EDEDED;
      padding: 3.4rem 1.7rem 4rem;
      gap: 1rem;
    }
    & h1 {
      font-family: $family_Ermilov;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 5rem;
      line-height: 6rem;
      color: #609F90;
      max-width: 74rem;
    }

    & h4{
      max-width: 46rem;
    }
    &__wrapper{
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 2rem;


    }
  }
  .article{
    @media screen and (max-width: 666px) {
      padding: 2rem 3rem 5rem;
    }
  }
  &-article{
    background: $bg-color;
    padding: 4rem 5rem;
    @media screen and (max-width: 666px) {
      background: none;
      padding: 0;
    }
    .article-header{
      @media screen and (max-width: 666px) {
        flex-direction: column;
      }
      & h2{
        @media screen and (max-width: 666px) {
          display: flex !important;
          padding-bottom: 3.3rem;
        }
      }
    }

    .article{
      width: 100%;
      &__wrapper{
        gap: 1.2rem;
        & > a{
          background: #FFFFFF;
          width: 24%;
          @media screen and (max-width: 666px) {
            width: 100%;
          }
        }
      }
    }
  }
  &-search{
    background: #FFFFFF;
    &__wrapper{
      padding: 4.4rem 5rem 6rem;
      @media screen and (max-width: 666px) {
        padding: 3.4rem 2rem 4.5rem;
      }
    }
  }
}

.form-objects{

  &__inputs{
    display: grid;
    grid-template-columns: 2fr 1fr .8fr;
    @media screen and (max-width: 666px) {
      display: flex;
      flex-direction: column;
    }

    &-wrapper{
      display: flex;
      flex-wrap: wrap;
      gap: 2.1rem;
      width: 100%;
      border-right: .1rem solid #DEE3E7;
      @media screen and (max-width: 666px) {
        flex-direction: column;
        border-right: none;
        gap: .5rem;
      }
      &:last-child{
        margin: 0 2rem;
      }
      &:nth-child(2){
        gap: 0;
        @media screen and (max-width: 666px) {
          margin-bottom: 4.2rem;
        }
        & > label {
          @media screen and (max-width: 666px) {
            padding-bottom: 1.2rem;
          }
          @media screen and (min-width: 666px) {
            margin: 0 3rem;
          }
        }
      }
    }
    &-button{
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .base-select{
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    & > label{
      padding-left: .5rem;
      white-space: nowrap;
    }
  }
  .base-title{
    display: flex;
    gap: 1.6rem;
    align-items: center;
    padding-bottom: 3.3rem;
  }
  .count-posts{
    background: #E2F3EF;
    border-radius: .4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    width: 4rem;
    height: 3rem;
    & > h5{
      color: #609F90;
    }
  }
}
.button-clear-all{
  background: #FFFFFF;
  border: .1rem solid #DEE3E7;
  width: 10.4rem;
  border-radius: .4rem;
  height: 3rem;
  padding: 0 2rem;
  @media screen and (max-width: 666px) {
    width: 12.8rem;
    white-space: nowrap;
  }
  & > h5{
    white-space: nowrap;
  }
}
.button-base-dameges, .button-search{
  background: #465558;
  width: 19rem;
  height: 5rem;

  & > h4{
    color: #F1F3F4;
    font-weight: 600;
  }
  &:hover{
    & > h4{
      color: #465558;
    }
    border: .1rem solid #465558;
  }
  &:active{
    & > h4{
      color: #212121;
    }
    border: 1px solid #212121;
  }
}

.vw-26{
  width: 18vw;
  max-width: 26rem;

}
.vw-25{
  width: 17.3vw;
  max-width: 25rem;
}
.vw-9 {
  width: 6.6vw;
  max-width: 9.4rem;
  @media screen and (max-width: 666px) {
    width: 50%;
    max-width: 16.5rem;
  }
}
.vw-30 {
  width: 21vw;
  max-width: 30rem;
  margin-left: 2.5rem;
}
.vw-26, .vw-25, , .vw-30{
  @media screen and (max-width: 666px) {
    width: 100%;
    max-width: unset;
    margin-left: 0;
  }
}

.base-damages-Picker{
  @media screen and (min-width: 667px) {
    left: 54.5vw !important;
  }
  @media screen and (min-width: 1441px) {
    left: 52.5vw !important;
  }
}
.ui-datepicker table{
  font-size: 1.2rem;
}
.ui-datepicker-header{
  background: none;
  border: none;
  font-size: 1.6rem;
  color: #465558;
}
.ui-datepicker-calendar thead tr{
  border-top: .1rem solid #DEE3E7;
  border-bottom: .1rem solid #DEE3E7;
  color: #DEE3E7;
}
.ui-widget.ui-widget-content{
  padding: 2rem 4rem;
  width: 29.5rem;
  height: 27rem;
  border-radius: 0 0 1rem 1rem;
}
.ui-datepicker td > a{
  display: flex;
  justify-content: center;
  align-items: center;
  border: none!important;
  background: none!important;
  width: 2.8rem;
  height: 2.8rem;
  color: #465558 !important;
}
.ui-state-default.ui-state-active{
  background: #E2F3EF !important;
  color: #609F90;
}
.ui-datepicker-unselectable.ui-state-disabled{
  width: 2.8rem;
  height: 2.8rem;
  & > span{
    display: flex;
    justify-content: center;
    border: none!important;
    background: none!important;
  }
}

.ui-datepicker-today{
  background: none;
  border: none;

}
.ui-datepicker .ui-datepicker-prev, .ui-datepicker .ui-datepicker-next{
  display: none;
}
.ui-datepicker .ui-datepicker-title{
  white-space: nowrap;
  display: flex;
  gap: 1rem;
  margin: 0 1em!important;
}
.ui-datepicker select.ui-datepicker-month, .ui-datepicker select.ui-datepicker-year{
  width: 60%;
}
.ui-datepicker select.ui-datepicker-month{
  width: 90%;
}
.ui-datepicker-trigger img{
  width: 2rem;
  height: 2rem;
}
#datepicker1, #datepicker2{
  //background: #E2F3EF;
  background: #FFFFFF;
  border: .1rem solid #DEE3E7;
  border-radius: 1rem;
  width: 13.8rem;
  height: 5rem;
  font-family: $family_Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.2rem;
  letter-spacing: 0.02em;
  color: #465558;
  padding-left: 1rem;
  &:focus{
      border-radius: 1rem 1rem 0 0;
      border: .1rem solid #DEE3E7;
  }
  @media screen and (max-width: 1280px) {
    font-size: 1.3rem;
    line-height: 2rem;
  }
  @media screen and (max-width: 820px) {
    font-size: 1.2rem;
    line-height: 2rem;
  }
  @media screen and (max-width: 666px) {
    width:100%;
    font-size: 1.6rem;
    line-height: 2.2rem;
  }
}

.base-datapicker{
  display: flex;
  gap: 2rem;
  margin: 0 3rem;
  &:focus{
    border-radius: 1rem 1rem 0 0;
    border: .1rem solid #DEE3E7;
  }
  @media screen and (max-width: 666px) {
    margin: 0;
  }
}
.base-datapicker_one{
  position: relative;
  @media screen and (max-width: 666px) {
    width: 50%;
  }
}
.ui-datepicker-trigger{
  position: absolute;
  top: 1.5rem;
  right: .3rem;
  z-index: 10;
  padding: 0rem 1rem 1rem 9rem;
}
.article__main{
  flex-direction: column;
}
.base-damages-one-post, .article{
  &__main{
    padding: 6.1rem 5rem;
    background: #EAEAEA;
    display: flex;
    gap: 5rem;
    position: relative;
    .list-cards__card {
      &:hover {
        .card-info, .icon {
          border: none;
        }

      }
    }
  }
  .base-damages-card{
    display: flex;
    justify-content: center;
    align-items: center;
    &__wrapper{
      background: #FDFDFD;
      border: .1rem solid #465558;
      position: relative;
      .card-title {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 3rem 2.5rem 0;
        gap: 2.7rem;
        & > p {
          color: $green-color;
        }
      }
      .icon {
        position: absolute;
        background: $light-green-color;
        border: .1rem solid #465558;
        border-radius: 50%;
        top: -3rem;
        left: 50%;
        width: 5.6rem;
        height: 5.6rem;
        display: flex;
        justify-content: center;
        align-items: center;
        transform: translateX(-50%);

        & > img {
          width: 3.2rem;
          height: 3.2rem;
        }
      }

      .card-info{
        padding: 6rem 3rem 3.5rem;
        display: flex;
        gap: 3rem;


        &__line{
          width: .1rem;
          background-color: #DEE3E7;
        }
        &__wrapper{
          width: 50%;
          & h4{
            color: #609F90;
            padding-bottom: 1rem;
          }
          & p{
            padding-bottom: 1rem;
          }
        }

      }

    }
  }
  .swiper-button-prev-damages1, .swiper-button-next-damages1, .swiper-button-next-gallery, .swiper-button-prev-gallery {
    position: absolute;
    top: 40%;
    width: calc(var(--swiper-navigation-size) / 44 * 27);
    height: var(--swiper-navigation-size);
    margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .swiper-button-prev-damages1,.swiper-button-next-gallery, .swiper-button-prev-gallery, .swiper-button-next-damages1 > svg{
    width: 3rem;
    height: 4.2rem;
  }
  .swiper-button-prev-damages1, .swiper-button-prev-gallery {
    left: 0;
    top: 50%;
  }
  .swiper-button-next-damages1, .swiper-button-next-gallery {
    top: 50%;
    right: 0;
  }
  .swiper-button-next-damages1, .swiper-button-next-gallery{
    transform: rotate(-180deg);
  }
  &__img{
    width: 43vw;
    max-width: 61rem;
    height: 53rem;
  }
  .swiper {
    width: 100%;
    height: 100%;
  }
  .swiper-slide {
    text-align: center;
    font-size: 1.8rem;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .base-damages-slider .swiper-wrapper{
    height: 42rem;
  }
  .base-damages-gallery{
    height: 12rem;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  body {
    background: #000;
    color: #000;
  }

  .swiper {
    width: 100%;
    height: 30rem;
    margin-left: auto;
    margin-right: auto;
  }

  .swiper-slide {
    background-size: cover;
    background-position: center;
  }

  .base-damages-slider {
    height: 80%;
    width: 100%;
  }

  .base-damages-gallery {
    height: 26%;
    box-sizing: border-box;
    padding: 1rem 0;
  }

  .base-damages-gallery .swiper-slide {
    width: 25%;
    height: 100%;
    opacity: 0.4;
  }

  .base-damages-gallery .swiper-slide-thumb-active {
    opacity: 1;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.base-damages-one-post{
  &__main{
    @media screen and (max-width: 666px) {
      display: flex;
      flex-direction: column;
      padding: 5rem 2rem 2rem;
      background: $white;
      gap: 0;
    }
    .swiper-slide{
      @media screen and (max-width: 666px) {
        background: none;
      }
    }
    .article-info{
      @media screen and (min-width: 666px) {
        display: none;
      }
      .card-info{
        position: absolute;
        top: 26.5rem;
        left: 50%;
        transform: translateX(-50%);
        z-index: 100;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .icon {
          background: $light-green-color;
          border: 1px solid $stroke-color;
          border-radius: 50%;
          width: 5.6rem;
          height: 5.6rem;
          display: flex;
          justify-content: center;
          align-items: center;

          & > img {
            width: 3.2rem;
            height: 3.2rem;
          }
        }
        & > p{
          white-space: nowrap;
          color: $green-color;
        }
      }
      & h2{
        text-align: center;
        padding-bottom: 3rem;
      }
      & h4{
        color: $green-color;
        padding-bottom: 1rem;
      }
      & p{
        padding-bottom: 2rem;
      }
      .line{
        width: 100%;
        height: .1rem;
        background: #DEE3E7;
        margin: 1rem 0 3rem;
      }
    }
    .swiper{
      @media screen and (max-width: 666px) {
        height: 33rem;
      }
    }
    .slider-history1{
      .swiper-pagination {
        padding-right: 1.8rem;
        & > .swiper-pagination-bullet {
          width: 22%;
          height: .2rem;
          background: #9da0a3;
          border-radius: unset !important;
          transition: all .5s linear;
        }

        & > .swiper-pagination-bullet-active {
          background: $black;
        }
      }

      .swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet, .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
        margin: 0 !important;
      }
    }
    .base-damages-one-post__img, .base-damages-card{
      @media screen and (max-width: 666px) {
        display: none;
      }
    }
    #slider-history{
      @media screen and (min-width: 666px) {
        display: none;
      }
      .list-cards__card{
        width: 100%;
        height: inherit;
        .card-img{
          height: 24rem;
          border: .1rem solid #465558;
        }
      }
    }

  }
}
.base-damages_info{
  display: flex;
  padding: 2rem 5rem 5rem;
  background: #EAEAEA;
  gap: 5.6rem;
  @media screen and (max-width: 666px) {
    padding: 0 2rem 0;
    flex-direction: column;
    gap: 0;
    background: none;
  }
  &__history{
    width: 55%;
    @media screen and (max-width: 666px) {
      width: 100vw;
      background: #F1F3F4;
      margin: 0 -2rem;
    }
    & > h3{
      @media screen and (max-width: 666px) {
        padding: 2.5rem 2rem 3.2rem !important;
      }
    }
    .another-history{
      gap: 1.2rem;
      display: flex;
      flex-wrap: wrap;
      @media screen and (max-width: 666px) {
        flex-direction: column;
        gap: 3rem;
        padding-bottom: 5rem;
      }
      & > a{
        background: #FFFFFF;
        width: 48%;
        max-width: 32.5rem;
        @media screen and (max-width: 666px) {
          margin: 0 2rem;
          width: 91%;
          max-width: unset;
          border: .1rem solid #DEE3E7;
        }
      }
    }
  }
  &__video{
    width: 47%;
    @media screen and (max-width: 666px) {
      width: 100%;
    }
    .video-post{
      width: 46vw;
      max-width: 62rem;
      height: 35rem;
      margin-bottom: 4rem;
      border-radius: 1rem;
      @media screen and (max-width: 666px) {
        width: 100%;
        max-width: unset;
        height: 20rem;
        margin-bottom: 2rem;
      }
      iframe{
        width: 100%;
        height: 100%;
        border-radius: 1rem;
      }
      &:last-child.video-post{
        margin-top: 2rem;
        @media screen and (max-width: 666px) {
          margin-top: 0;
        }
      }
    }
    p {
      padding-bottom: 2rem;
      line-height: 2.2rem;
    }

  }
  &__video, &__history{
    & > h3{
      padding-bottom: 2rem;
    }
  }
}

.date-publish .select2.select2-container{
  @media screen and (max-width: 666px) {
    width: 100% !important;
    max-width: 21rem !important;
  }
}
.date-publish .select2.select2-container .select2-selection{
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  @media screen and (max-width: 666px) {
    height: 5rem !important;
  }
}
.date-publish .select2.select2-container::after{
  @media screen and (max-width: 666px) {
    top: 2.7rem !important;
  }
}
.date-publish .select2.select2-container.select2-container--open:after{
  @media screen and (max-width: 666px) {
    top: 2.3rem !important;
  }
}
.date-publish .select2.select2-container .select2-selection .select2-selection__rendered{
  @media screen and (max-width: 666px) {
    line-height: 3.4rem !important;
  }
}
.border-click{
  border: 1px solid #465558 !important;
}
.desc{
  font-family: $family_Ermilov;
  font-style: normal;
  font-weight: 700;
  font-size: 5rem;
  line-height: 6rem;
  text-transform: uppercase;
  color: #609F90;
  max-width: 100rem;
  @media screen and (max-width: 666px) {
    display: none;
  }
}
.mobile{
  font-size: 3.8rem !important;
  line-height: 4.4rem !important;
  font-family: $family_Ermilov;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
  color: #609F90;
  @media screen and (min-width: 666px) {
    display: none;
  }
}
.not-find{
  & > h5{
    color: #FF4A4A!important;
  }
  background: #FFD7D7!important;
}
.error-select{
  color: #FF4A4A!important;
}
.date-publish-disable{
  opacity: 0.3;
}
.date-publish-disable::after{
  content: '';
  background: none;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100;
}
.filter-active{
  border: 1px solid #465558!important;
}