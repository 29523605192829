@import '../app/variable.scss';
@import '../app/placeholder.scss';

.footer{
  background: #465558;
  &-logo{
    & > a > svg{
      width: 7.5rem;
      height: 7.5rem;
    }
    display: flex;
    align-items: center;
    height: 13.5rem;
    padding-right:3.4rem;
    border-right: 2px solid #69797C;
  }
  &-info{
    display: flex;
    gap: .8rem;
    flex-direction: column;
    padding-left:3.4rem;
    & > a > p{
      color: $white;
    }
    & > a > p:hover{
      color: #E2F3EF;
    }
  }
  &__wrapper{
    display: flex;
    padding: 4rem 0 3rem;
    justify-content: center;
    align-items: center;
  }
}