@import '../app/variable.scss';
@import '../app/placeholder.scss';

.help{
  &-header{
    display: flex;
    justify-content: space-between;
    padding: 7rem 5rem;
    background: $bg-color;
    align-items: center;
    @media screen and (max-width: 666px) {
      background: #EDEDED;
      padding: 3.4rem 1.7rem 1.9rem;
    }
    & > h2{
      font-family: $family_Ermilov;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 5rem;
      line-height: 6rem;
      color: #609F90;
      @media screen and (max-width: 666px) {
        font-size: 3.8rem;
        line-height: 4.4rem;
      }
    }
  }
  &__wrapper{
    display: flex;
    gap: 2.9rem;
    padding: 4rem 5rem 8rem;
    @media screen and (max-width: 666px) {
      flex-direction: column-reverse;
      padding: 0;
      gap: 0;
    }
    }
  &-pagination{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 6.3rem;
    @media screen and (max-width: 666px) {
      padding-top: 3rem;
    }
  }

    .base-select{
      display: flex;
      flex-direction: column;
      gap: 1.2rem;
      & > label{
        padding-left: .5rem;
        white-space: nowrap;
      }
    }
    .base-title{
      display: flex;
      gap: 1.6rem;
      align-items: center;
      padding-bottom: 3.3rem;
    }
    .count-posts{
      background: #E2F3EF;
      border-radius: .4rem;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1rem;
      color: #609F90;
      width: 4rem;
      height: 3rem;
    }
}
.navigator{
  & > h5{
    color: #B9B9B9;
    & > span{
      padding: 0 .8rem;
      font-size: 1.5rem;
      color: #C0C0C0;
    }
    @media screen and (max-width: 666px) {
      display: none;
    }
  }
}
.sidebar{
  padding-top: 7.5rem;
  display: flex;
  flex-direction: column;
  gap: 3.8rem;
  width: 21.5vw;
  @media screen and (max-width: 666px) {
    width: unset;
    gap: unset;
    padding: 0;
  }
  &-top{
    display: flex;
    gap: 3rem;
    flex-direction: column;
    justify-content: center;
    background: #F9F9F9;
    border: .1rem solid #F1F3F4;
    padding: 3rem;
    &__list{
      display: flex;
      gap: 1.8rem;
      flex-direction: column;
      & > a:not(:last-child){
        padding-bottom: 1.7rem;
        border-bottom: .1rem solid #DEE3E7;
      }
      & > a:hover{
        p{
          color: #212121;
        }
      }
    }
  }
  &-memories{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 3rem 2.3rem;
    gap: 2.6rem;
    background: #E2F3EF;
    border: .1rem solid #609F90;
    @media screen and (max-width: 666px) {
      margin:2.4rem;
    }
    .button-memory{
      width: 20rem;
      background: #FFFFFF;
      border: .1rem solid #E8E8E8;
      color: #465558;
    }
  }
}
.article{
  padding: 0 0 6rem;
  display: flex;
  flex-direction: column;
  gap: 3.5rem;
  width: 70vw;
  @media screen and (max-width: 666px) {
    width: unset;
    background: #EDEDED;
    padding: 0 3rem 5rem;
  }
  &-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 666px) {
      justify-content: center;
      & > h2 {
        display: none;
      }
    }
  }
  &__wrapper{
    display: flex;
    gap: .7rem;
    flex-wrap: wrap;
    @media screen and (max-width: 666px) {
      flex-direction: column;
      gap: 3rem;
    }
    & > a{
      width: 32%;
      border: .1rem solid #DEE3E7;
      @media screen and (max-width: 666px) {
        width: 100%;
        background: #FFFFFF;
      }
      & .card-img{
        height: 22.9vw;
        max-height: 33rem;
        border: .2rem solid #DEE3E7;
        @media screen and (max-width: 666px) {
          height: 80vw;
        }
      }
    }
  }
}
.filter-font{
  & > ul{
    font-size: 1.2rem !important;
    border-radius: .5rem !important;
    @media screen and (max-width: 666px) {
      font-size: 1.6rem !important;
    }
    & > li{
      padding: .75rem 1.3rem !important;
    }
  }
}
.mtop{
  margin-top: .5rem !important;
}
.date-publish{
  display: flex;
  gap: .8rem;
  position: relative;
  @media screen and (max-width: 666px) {
    width: 100%;
  }
  .select2.select2-container {
    @media screen and (min-width: 666px) {
      width: 15rem !important;
    }
  }
  .select2.select2-container .select2-selection{
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    height: 4rem;
    margin-bottom: 0;
    border-radius: .5rem !important;
    padding: .7rem .5rem;
  }


  .select2-container--default.select2-container--open.select2-container--below .select2-selection--single{
    border-radius: .5rem !important;
  }
  .select2.select2-container .select2-selection .select2-selection__rendered{
    line-height: 2.4rem;
  }
  .select2.select2-container::after{
    top: 2rem;
  }
  .select2-results__option--selectable{
    padding: 1rem 1.3rem !important;
  }
  .select2.select2-container.select2-container--open:after{
    top: 1.7rem;
  }
  .select2.select2-container, .select2-container .select2-dropdown .select2-results, .select2-container .select2-dropdown .select2-results{
    @media screen and (min-width: 666px) {
      font-size: 1.2rem !important;
    }
  }
  .date-publish-button-drop{
    //display: none;
    position: absolute;
    top: 5rem;
    border: .1rem solid #465558;
    border-radius: .5rem;
    .button-drop__one{
      background: #F1F3F4;
      padding: 1rem 3rem 1rem 1.5rem;
    }
  }
  &-button{
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: .9rem;
    background: #FFFFFF;
    border-radius: .5rem;
    border: .1rem solid #DEE3E7;
    height: 4rem;
    cursor: pointer;
    @media screen and (max-width: 666px) {
      height: 5rem;
      width: 5rem;
    }
    & > svg{
      width: 1.9rem;
      height: 1.9rem;
    }
    &.active{
      border: .1rem solid #465558;
    }
    &:active{
      border: .1rem solid #465558;
      background: #DEE3E7;

    }
  }
  #date-publish-all{
    width: 15rem;
    border: .1rem solid #465558;
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    @media screen and (max-width: 666px) {
      width: 21rem;
    }
    & > svg{
      width: 1rem;
      height: 1rem;
    }
  }
  #date-publish-down{
    transform: rotate(-180deg);
  }
}

.main-last-add{
  .swiper-pagination > .swiper-pagination-bullet{
    width: 12%!important;
  }
}