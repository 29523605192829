@import '../app/variable.scss';
@import '../app/placeholder.scss';

.header{
  &__wrapper{
    padding: 2.4rem 4rem;
    background: #FDFDFD;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 666px) {
      padding: 1.8rem 3.6rem 1.8rem 2rem;
      gap: 4rem;
      height: 11.3rem;
      .header__burger-mobile{
        display: flex;
      }
      .header-profile{
        height: 2rem;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      }
      .header-mobile-search{
        cursor: pointer;
      }
    }
    @media screen and (min-width: 666px) {
      .header__burger-mobile{
        display: none;
      }
    }
  }
  &-logo{
    display: flex;
    gap: 3.5rem;
    @media screen and (max-width: 666px) {
      padding-right: 2rem;
    }
    & > a > svg{
      width: 7.5rem;
      height: 7.5rem;
    }
    & > p{
      @media screen and (max-width: 666px) {
        display: none;
      }
      max-width: 32.9rem;
      color: $darkGray-color;
      display: flex;
      align-items: center;
    }
  }
  &__wrapper-search{
    gap: 4rem;
    justify-content: flex-end;
    align-items: center;
    @media screen and (min-width: 666px) {
      display: flex;
      max-width: 70rem;
      width: 100%;
    }
  }
  &-social{
    @media screen and (max-width: 666px) {
      display: none;
    }
    display: flex;
    gap: 5rem;
    &__icon{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 1rem;
      gap: 1rem;
      width: 5rem;
      height: 5rem;
      background: $white;
      border: .1rem solid $stroke-color;
      border-radius: 1rem;
      &:hover{
        & > svg{
          width: 2rem;
          height: 2rem;
          opacity: 1;
        }
      }
      &:active{
        border: .1rem solid #465558 !important;
      }
      & > svg{
        width: 2rem;
        height: 2rem;
        opacity: 0.4;
      }
    }

    &__line{
      border: .1rem solid #DEE3E7;
      transform: rotate(15deg);
    }
  }
  &-search{
    @media screen and (max-width: 666px) {
      display: none;
      width: 100%;
      margin-left: 1.6rem;
    }
    border-bottom: .1rem solid #465558;
    width: 29rem;
    height: 4rem;
    display: flex;
    gap: 1rem;
    align-items: center;
    position: relative;
    transition: all 0.5s ease-out;
    & > input{
      width: 100%;
      height: 95%;
      padding: 1rem;
      font-family: $family_Inter;
      font-style: normal;
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 2.2rem;
      letter-spacing: 0.02em;
      color: $darkGray-color;
      background: transparent;
      &:focus{
        outline: none;
        animation-name: widthFull;
        animation-duration: 0s;
        animation-direction: normal;
      }
    }
    & > svg{
      width: 1.6rem;
      height: 1.6rem;
    }
  }
  &-profile{
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 4rem;
    & > svg{
      width: 1.8rem;
      height: 2.2rem;
    }
  }
  .width-search{
    width: inherit;
  }
  .close-button-search{
    position: absolute;
    right: 0;
    top: 30%;
    & > svg{
      width: 2rem;
      height: 2rem;
    }
  }
  &-menu{
    @media screen and (max-width: 666px) {
      display: none;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3.5rem;
    & > a{
      padding: 1rem 0;
      &.active{
        border-bottom: .2rem solid #212121;
        & > h3{
          color: $black;
        }
      }
      & > h3{
        color: $green-color;
      }
      //&:hover{
      //  & > h3{
      //    color: #B9B9B9;
      //  }
      //}
    }
  }
  &-dropdown{
    display: flex;
    align-items: center;
    gap:1.4rem;
    position: relative;
    padding: 1rem 0;
    cursor: pointer;
    z-index: 10;
    & > h3{
      color:$green-color;
    }
    & > svg {
      width: 1.4rem;
      height: .7rem;
    }
    &-open{
      & > svg{
        opacity: 0.5;
        filter: brightness(0.4);
        transform: rotateX(-180deg);
      }
      & > h3{
        opacity: 0.5;
        filter: brightness(0.4);
      }
    }
    &-list{
      display: none;
      position: absolute;
      top: 6rem;
      left: -4rem;
      width: 19rem;
      border: .1rem solid #DEE3E7;
      border-radius: 1rem;
      background: $white;
      & > a{
        padding: 1.5rem;
        border-bottom: .1rem solid #EAEAEA;
        width: 100%;
        & > p{
          color: #465558;
        }
        &:hover{
          background: $bg-color;
        }
        &:active{
          background: #E2F3EF;
        }
      }
    }
  }
  &-menu-mobile{
    position: absolute;
    width: 100vw;

    height: 100vh;
    top: 0;
    display: none;
    //z-index: 1000;
    &::after{
      position: absolute;
      content: "";
      width: 100vw;
      height: 100vh;
      background: #212121;
      opacity: 0.3;
      top: 0;
      left: 0;
      z-index: -1;
    }
    @media screen and (min-width: 666px) {
      display: none;
    }
    &.open{
      display: block;
      transition: all .3s linear;
      z-index: 1000;

    }
    &__wrapper{
      overflow-y: auto;
      width: 87vw;
      background: $darkGray-color;
      border: .1rem solid #000000;
      height: 100vh;
      padding: 3rem 0 40%;
      top: 0;
      & > a{
        padding: 1.6rem 3.6rem;
        width: 100%;
        &.active{
          background: $black;
        }
      }
      h2{
        color: $light-green-color;
      }
      &-dropdown{
        cursor: pointer;
        padding: 1.6rem 3.6rem;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        & > h2{
          padding-bottom: 2rem;
        }
        &-list{
          padding-left: 2rem;
          display: flex;
          flex-direction: column;
          gap: 1.6rem;
          & > a > h3{
            color: $light-green-color;
          }
        }
      }
      .mobile-social{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4rem;
        padding-top: 3rem;
        & svg{
          opacity: 1!important;
        }
      }
    }
  }

  .header-burger{
    @media screen and (min-width: 666px) {
      display: none;
    }
    cursor: pointer;
    & > img{
      width: 2.8rem;
      height: 2.2rem;
    }
    padding-right: 2rem;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  .header-mobile-search{
    & > svg{
      width: 2.7rem;
      height: 2.5rem;
    }
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  .close-mobile-burger{
    position: absolute;
    right: 0;
    top: 0;
    background: #FFFFFF;
    width: 13vw;
    height: 11.3rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    & > svg{
      width: 4rem;
      height: 4rem;
    }
  }
}
.hidden-scroll{
  overflow: hidden;
  height: 100vh;
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}
@keyframes widthFull {
  0% {
    width: 29rem;
  }
  100% {
    width: inherit;
  }
}
